import DataLayer from 'app/modules/datalayer';
import GenericModelView from 'app/modelviews/genericmodelview';
import GenericModel from 'app/models/genericmodel';
import Logger from 'app/shared/logger';
import MediaLoader from 'app/modules/medialoader';
import MediaQueryEvent from 'app/shared/mediaqueryevent';
import {
  ensureInstanceOf,
  formUrlWithParams,
  getCookieByName,
  getValue,
  prependLanguagePrefix,
} from '../shared/utils';

/**
 * EndOfContentPlaylist displays the video playlist set at the section level
 *
 * **It will not display if there is no playlist set**
 *
 * @class  endofcontentplaylist
 * @extends Backbone.View
 *
 * @param {Object} [options] Content options
 */
export default Backbone.View.extend(
  /** @lends endofcontentplaylist# */ {
    el: '.standard-body-content, .listicle-body-content, .recipe-body-content',

    endpoint: prependLanguagePrefix('ajax/endofcontentplaylist/'),

    logger: new Logger('EndOfContentPlaylist'),

    initialize: function initialize() {
      if (!this.$el.length) {
        return;
      }
      this.setVariables();
      this.setListeners();
    },

    setVariables: function setVariables() {
      this.overrideDefaults({
        // Media loader for module media
        mediaLoader: null,
      });

      this.window = this.options.window || window;

      this.mediaLoader = ensureInstanceOf(this.mediaLoader, MediaLoader);

      this.model = new GenericModel();

      this.modelView = new GenericModelView({
        model: this.model,
        templateSelector: '#eoc-playlist-template',
      });

      this.isContentSponsored = !_.isEmpty(DataLayer.getValue('content.sponsor'));

      this.sectionId = this.modelView.$template.data('sectionId');

      // Add unique url for fetching
      // E.g: ajax/endofcontentplaylist/?id=123-456-789
      this.model.url = formUrlWithParams(this.endpoint, { id: this.sectionId });

      this.playerSettings = { hideTitle: true };
      this.titleElement = null;
      this.playerEvents = {
        onLoaded: this.onPlayerLoaded.bind(this),
        onPlaylistItemChange: this.onPlayerPlaylistItemChange.bind(this),
      };

      this.glimmerChannel = Backbone.Radio.channel('glimmer');

      this.mediaQueryChannel = MediaQueryEvent;

      this.isInterpolated = false;
    },

    setListeners: function setListeners() {
      this.model.on('sync', this.onSuccess.bind(this));
      this.glimmerChannel.on('player-loaded', this.onGlimmerLoaded.bind(this));
    },

    /**
     * set up any handlers necessary in here for glimmer to be loaded.
     */
    onGlimmerLoaded: function onGlimmerLoaded(player) {
      player.events.on('playlistitem', this.handleGlimmerPlaylistItem.bind(this));
    },

    getPlayerApi: function getPlayerApi() {
      return window.PlayerApi || window.hearstPlayer;
    },

    getCustomSettings: function getCustomSettings() {
      window.hdsCustomSettingById = window.hdsCustomSettingById || {};
      return window.hdsCustomSettingById;
    },

    setTitleElement: function setTitleElement(source) {
      this.titleElement = source.find('.video-title');
    },

    onPlayerLoaded: function onPlayerLoaded(player) {
      this.titleElement.show();
      player.setPlayerSettings(this.playerSettings);
    },

    onPlayerPlaylistItemChange: function onPlayerPlaylistItemChange(player, event) {
      this.titleElement.text(getValue('item.title', event, ''));
    },

    /**
     * Helper method for setting the title element in the watch next with proper styling.
     * TODO: replace this with a call to the player API itself.
     */
    handleGlimmerPlaylistItem: function handleGlimmerPlaylistItem(event) {
      try {
        const player = event.target.player;
        const playlistItem = player.playlist.getCurrentVideoSource();
        let displayTitle = playlistItem.title;

        if (this.isInterpolated && playlistItem.shortTitle) {
          displayTitle = playlistItem.shortTitle;
        }

        this.titleElement.show();
        this.titleElement.text(displayTitle);
      } catch (error) {
        this.logger.log('Error grabbing title from player events.');
      }
    },

    setPlayerCustomSettings: function setPlayerCustomSettings(embedId) {
      let settings = this.getCustomSettings();
      settings[embedId] = _.extend(settings[embedId] || {}, this.playerEvents);
    },

    getPlayerEl: function getPlayerEl($module) {
      return $module.find('.glimmerPlayer, .hearstPlayer');
    },

    isGlimmerPlayer: function isGlimmerPlayer($el) {
      // TODO: Fix this so we are not checking for hasclass here
      return $el.hasClass('glimmerPlayer');
    },

    /**
     * decides whether or not we should show the end-of-content playlist section
     * @return {boolean}
     */
    shouldGetSection: function shouldGetSection() {
      if (!this.sectionId) {
        this.logger.log('No section ID, exiting');
        return false;
      }

      if (this.isContentSponsored) {
        this.logger.log('Content is sponsored, exiting');
        return false;
      }

      return true;
    },

    fetch: function fetch() {
      if (!this.shouldGetSection()) {
        return false;
      }

      this.logger.log('Fetching EOC Playlist', {
        sectionId: this.sectionId,
      });

      // if we already have the player API, we can just start the model fetching process.
      // however, if we don't have it, that means that the EOC playlist is the only player
      // on the page and requires the player.js to be loaded via the `loadResources` below
      return this.fetchModel();
    },

    /**
     * Helper method to ensure proper binding of `this` in the fetch method above
     */
    fetchModel: function fetchModel() {
      return this.model.fetch();
    },
    /**
     * Changes position of Watch Next Playlist
     */
    interpolateWatchNext: function interpolateWatchNext() {
      // if the article has sponsors then don't move up the watch next player
      const sponsors = getValue('HRST.article.sponsor', window, []);
      const { pianoOfferDisplayed, GDPR } = window;
      const gdprConsentCookie = getCookieByName('_evidon_consent_cookie');

      // don't move the watch next player into the body if the article has
      // sponsors or if the user is in a GDPR region and has not consented
      // to tracking or if there is currently a piano banner being displayed
      if (sponsors.length > 0 || (GDPR && !gdprConsentCookie) || pianoOfferDisplayed) {
        return;
      }
      const containerSelector = document.querySelector(
        '.end-of-content-playlist[data-interpolate]',
      );
      const targetSelector = document.querySelector('.article-body-content p:nth-of-type(3)');

      if (containerSelector) {
        if (targetSelector) {
          this.isInterpolated = true;
          // Update Header, Move
          const header = containerSelector.querySelector('.watch-next-header');
          const inBodyHeaderLabel = header.getAttribute('data-wn-label-body');

          targetSelector.insertAdjacentElement('afterend', containerSelector);
          header.textContent = inBodyHeaderLabel;
          containerSelector.classList.add('watch-next-in-body');

          // instruct player to keep mobile controls on desktop
          const playerEl = containerSelector.querySelector('.glimmerPlayer, .hearstPlayer');
          playerEl.setAttribute('data-mobile-controls-on-desktop', 'true');
        }
      }
    },

    onSuccess: function onSuccess() {
      let embedId = this.model.getAttributeFromData('embed_id');
      let playerApi = this.getPlayerApi();
      let $module;

      if (!embedId) {
        this.logger.log('Embed id not found');
        return;
      }

      $module = $(this.modelView.render().el);
      this.$el.append($module);

      // We need to find the class of the player , whether glimmer is present or not
      const $playerEl = this.getPlayerEl($module);

      this.setTitleElement($module);

      if (playerApi) {
        this.setPlayerCustomSettings(embedId);

        // fancy handling if we are using glimmer, otherwise just a simple init will do
        if (!this.isGlimmerPlayer($playerEl)) {
          playerApi.initPlayer($playerEl[0]);
        }
      }

      this.logger.log('EOC Playlist added', {
        videoEmbedId: embedId,
      });

      // Handles positioning of WN Player if conditions are right
      this.interpolateWatchNext();
    },
  },
);
